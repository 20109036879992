.permissionRows {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
}

.permissionLableSpan {
    padding-left: 5px;
}

.permissionModalFooter{
    display: flex;
    justify-content: flex-end;
}