*,
html,
body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}

:root {
  --theme-color: #0051d2;
  --theme-dark-color: #003fa3;
  --theme-dark-text: #002766;
  --theme-border-color: rgba(0, 81, 210, 0.4);
  --theme-hover-color: rgba(0, 81, 210, 0.12);
  --theme-highlight-color: rgba(0, 81, 210, 0.007);
  --sub-color: #777;
  --text-color: #333333;
  --border-color: rgb(231, 231, 231);
  --border-dark-color: rgb(200, 200, 200);
  --border-hover-color: rgba(231, 231, 231, 0.5);
  --success-background: rgb(221, 252, 230);
  --success-text: rgb(32, 124, 65);
  --error-color: #dd3333;
  --error-dark-color: #a61c1c;
  --error-background-color: rgba(237, 27, 36, 0.12);
  --shadow-color: 0deg 0% 74%;
}

a {
  color: var(--theme-color);
}

a:hover,
a:focus {
  color: var(--theme-dark-text);
}

.navbar-default .navbar-nav > li > a:not(.btn):focus,
.navbar-default .navbar-nav > li > a:not(.btn):hover {
  color: var(--theme-color);
}

.rdtPicker td.rdtToday {
  background-color: var(--theme-highlight-color);
}

button.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition: all 0.25s ease-in-out;
  color: var(--text-color);
  opacity: 0.95;
}

button.btn > svg {
  flex-shrink: 0;
}

button.btn-round {
  border-radius: 50% !important;
}

.round {
  border-radius: 50%;
}

.navbar .navbar-minimize {
  margin: 0 12px;
}

.navbar-minimize .btn-round {
  width: 32px;
  height: 32px;
}

.navbar-collapse {
  margin-left: auto;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
}

.switch {
  display: flex;
  flex-direction: column;
}

.switch .form-group .control-label {
  margin: 0;
}

.switch .form-group {
  display: flex;
  align-items: center;
  gap: 16px;
}

.btn-danger {
  color: var(--theme-dark-text);
  font-weight: 500;
  border-color: var(--theme-dark-text);
  border-width: 1px;
  padding: 7px 15px;
}

.btn-danger:hover,
.btn-danger:active {
  border-color: var(--theme-dark-color) !important;
  color: var(--theme-dark-text);
  background-color: var(--theme-hover-color);
}

.btn-danger:focus {
  border-color: var(--theme-dark-color) !important;
  color: var(--theme-dark-color);
  background-color: transparent;
}

.btn-danger:hover svg,
.btn-danger:active svg {
  color: var(--theme-dark-text);
}

.btn-danger.btn-fill:hover svg,
.btn-danger.btn-fill:active svg {
  color: #fff;
}

.btn-danger:focus svg {
  color: var(--theme-dark-color);
}

.btn-danger.btn-fill:focus svg {
  color: #fff;
}

.btn-danger.btn-fill {
  background-color: var(--theme-dark-color);
  color: white;
}

.btn-danger.btn-fill:hover {
  color: #ffffff;
  background-color: var(--theme-color);
}

.btn-danger,
.btn-danger:disabled,
.btn-danger.disabled {
  border-color: var(--theme-color);
}

.btn.btn-error {
  border-color: var(--error-color);
  color: var(--error-dark-color);
}

.btn-error:hover,
.btn-error:active {
  color: var(--error-color);
  border-color: var(--error-color);
  background-color: var(--error-background-color);
}

.btn-error.btn-fill {
  border-color: var(--error-color);
  background-color: var(--error-color);
  color: white;
}

.btn-error.btn-fill:hover,
.btn-error.btn-fill:active {
  border-color: var(--error-dark-color);
  background-color: var(--error-dark-color);
}

.btn.btn-empty {
  border: none;
  cursor: pointer;
  padding: 4px 10px;
}

.btn.btn-empty:hover {
  background-color: var(--theme-hover-color);
}

.grid {
  display: grid;
}

.grid-2col {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-3col {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-2col .col-2span {
  grid-column: 1 / span 2;
}

.grid-2col .col-3span {
  grid-column: 1 / span 2;
}

.grid-3col .col-3span {
  grid-column: 1 / span 3;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex.items-center,
.grid.items-center {
  align-items: center;
}

.flex.items-end,
.grid.items-end {
  align-items: flex-end;
}

.flex.items-start,
.grid.items-start {
  align-items: flex-start;
}

.flex.justify-center {
  justify-content: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mr-10 {
  margin-right: 10px;
}

.w-148 {
  width: 148px;
}

.gap-1 {
  gap: 1px;
}

.gap-2 {
  gap: 2px;
}

.gap-4 {
  gap: 4px;
}

.gap-6 {
  gap: 6px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-6 {
  width: 24px;
}

.h-6 {
  height: 24px;
}

.w-5 {
  width: 22px;
}

.h-5 {
  height: 22px;
}

.w-4 {
  width: 18px;
}

.h-4 {
  height: 18px;
}

.w-3 {
  width: 14px;
}

.h-34 {
  height: 14px;
}

.w-full {
  width: 100%;
}

.sub-text {
  color: var(--sub-color);
}

.main-text {
  color: var(--text-color);
}

.theme-text {
  color: var(--theme-dark-color);
}

.theme-dark-text {
  color: var(--theme-dark-text);
}

.success-text {
  color: var(--success-text);
}

.text-bold {
  font-weight: 700;
}

.text-regular {
  font-weight: 400;
}

.text-light {
  font-weight: 300;
}

.text-semibold {
  font-weight: 600;
}

.text-xxs {
  font-size: 12px;
}

.text-xs {
  font-size: 13px;
}

.text-normal {
  font-size: 14px;
}

.text-xl {
  font-size: 16px;
}

.text-xxl {
  font-size: 18px;
}

.text-nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.tap-sidebar {
  background: #fff;
  overflow-y: auto;
  border-right: 1px solid var(--border-color);
  width: 232px;
}

.tap-sidebar::before,
.tap-sidebar::after {
  display: none;
}

.tap-sidebar .sidebar-links {
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 0px !important;
}

.tap-sidebar .sidebar-wrapper {
  width: 100%;
  overflow: hidden;
  box-shadow: none;
  height: auto;
  padding-bottom: 24px;
}

.tap-sidebar .nav a p {
  font-weight: 600;
  font-size: 12px;
}

.tap-sidebar .nav a svg {
  flex-shrink: 0;
}

.tap-sidebar .nav li > a {
  margin: 0;
  padding: 3px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  border-radius: 5px;
}

.tap-sidebar .nav li > a > p {
  font-size: 11px;
  line-height: 22px;
}

.tap-sidebar .nav.collapse li > a {
  padding: 6px 0px;
}

.tap-sidebar .nav li > a > svg {
  width: 18px;
  height: 18px;
}

.tap-sidebar .nav a p {
  flex-grow: 1;
}

.tap-sidebar .nav a p,
.tap-sidebar .nav a svg,
.tap-sidebar .nav a i,
.tap-sidebar .nav.collapse a > span {
  color: var(--text-color);
}

.tap-sidebar .nav li.active > a p,
.tap-sidebar .nav li.active > a svg,
.tap-sidebar .nav li.active > a i {
  color: var(--theme-color);
}

.tap-sidebar .nav li:hover > a {
  background: #f4f4f4;
}

.tap-sidebar .nav li.active > a {
  background: var(--theme-hover-color);
  color: var(--theme-color);
}

.custom-select-option {
  transition: background 0.2s ease-in-out;
}

.custom-select-option:hover,
.custom-select-option:active,
.custom-select-option:focus {
  background: var(--theme-hover-color);
}

.tap-sidebar .nav p .caret {
  margin-left: auto;
}

.tap-sidebar .separator.horizontal {
  margin: 8px 0;
}

.sidebar-mini .tap-sidebar .nav a svg {
  margin-left: 4px;
}

.sidebar-mini .tap-sidebar .nav li.active > a p {
  opacity: 0;
  width: 0;
}

.avvy-sidebar-mini-icon {
  display: none;
}

.avvy-sidebar-large-icon {
  max-width: 80%;
  margin: 0 auto;
}

.sidebar-mini .avvy-sidebar-mini-icon {
  display: block;
}

.sidebar-mini .avvy-sidebar-large-icon {
  display: none;
}

.card {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border: 1px solid var(--border-color);
}

.card .header {
  padding: 10px 16px 0px 16px;
}

.card .title {
  font-weight: 500;
  font-size: 18px;
}

.separator {
  background: var(--border-color);
}

.separator.horizontal {
  height: 1px;
}

.separator.vertical {
  width: 1px;
  height: 100%;
}

.flex .separator.vertical {
  height: auto;
  align-self: stretch;
}

.nav > li.open > .dropdown-menu,
.dropdown.open .dropdown-menu,
.dropup.open .dropdown-menu,
.bootstrap-table .open .dropdown-menu {
  margin-top: 12px;
}

.nav > li > .dropdown-menu {
  border-radius: 6px;
  border-color: var(--border-color);
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.nav > li > .dropdown-menu:after,
.nav > li > .dropdown-menu:before {
  display: none;
}

.badge {
  font-weight: 600;
  padding: 5px 12px;
  font-size: 11px;
}

.badge.success {
  background-color: var(--success-background);
  color: var(--success-text);
}

.badge.error {
  background-color: var(--error-background-color);
  color: var(--error-color);
}

.badge.primary {
  background-color: #e5effa;
  color: #0169dc;
}

.badge.warning {
  background-color: #fde5b9;
  color: #c26700;
}

.badge.info {
  background-color: #f2f2f2;
  color: #636363;
}

.badge.fill.error {
  background-color: var(--error-color);
}

.badge.fill.primary {
  background-color: #0169dc;
}

.badge.fill.warning {
  background-color: #c26700;
}

.badge.fill.success {
  background-color: var(--success-text);
}

.badge.fill.info {
  background-color: #636363;
}

.availability-badge {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  padding: 0;
  flex-shrink: 0;
}

/* Tables */
.ReactTable .rt-noData {
  text-align: center;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.07) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.ReactTable .pagination-bottom .-center {
  display: none;
}

.ReactTable .pagination-bottom {
  padding-top: 10px;
  margin-left: auto;
}

.ReactTable .pagination-bottom .-pagination {
  gap: 0px;
}

.ReactTable .pagination-bottom .-pagination .-btn {
  padding: 6px 10px 2px 10px;
}

.ReactTable .pagination-bottom .-next button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ReactTable .pagination-bottom .-previous button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ReactTable .rt-resizer {
  width: 16px;
}

.ReactTable .rt-thead .rt-th:last-child {
  text-align: left;
}

.ReactTable .rt-thead .rt-th {
  font-weight: 600;
  font-size: 12px;
  text-align: left;
}

.ReactTable .rt-thead.-header {
  border-bottom: 1px solid var(--border-color);
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid var(--border-color);
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 1px solid var(--border-color);
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  color: var(--text-color);
}

.ReactTable.-striped .rt-tr.-odd {
  background-color: rgb(249, 250, 251);
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  font-size: 13px;
  font-weight: 400;
  padding: 8px 6px;
  text-overflow: ellipsis;
}

.pagination-btn {
  padding: 6px 12px;
}

.pagination-btn.left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-btn.right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.react-calendar-timeline {
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--border-color);
}

.react-calendar-timeline .rct-header-root {
  background: var(--theme-dark-color) !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.react-calendar-timeline .rct-sidebar {
  border-right: 1px solid var(--border-color) !important;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: 1px solid var(--border-color) !important;
  padding: 0 10px !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: rgba(0, 0, 0, 0.03) !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: var(--theme-highlight-color) !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid var(--border-color) !important;
}

.react-calendar-timeline .rct-calendar-header {
  border: none !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 1px solid var(--border-color) !important;
}

.react-calendar-timeline .rct-item-content {
  height: 100% !important;
  top: 0;
  left: 0;
  position: absolute !important;
  width: 100% !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  line-height: 14px !important;
  padding: 0 4px !important;
  flex-grow: 1;
}

.card .category,
.card .form-group > label {
  color: var(--text-color);
  font-weight: 600;
  font-size: 12px;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  width: 90%;
  max-width: 460px;
  min-width: 300px;
}

.modal-backdrop {
  background-color: rgba(107, 114, 128, 0.75);
}

.modal-dialog .modal-content,
.swal2-modal,
.sweet-alert,
.mapboxgl-popup .mapboxgl-popup-content {
  border-color: var(--border-color);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.swal2-popup.swal2-modal {
  width: 48em;
  max-width: 100%;
}

.swal2-popup .swal2-title {
  margin-bottom: 16px;
  font-size: 15px;
  line-height: 22px;
}

.swal2-popup .swal2-content {
  line-height: 22px;
}

.swal2-popup .swal2-actions {
  margin-top: 32px;
}

.sweet-alert h2 {
  font-size: 22px;
  font-weight: 600;
  margin-top: 0;
  color: var(--text-color);
}

.sweet-alert p {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-top: 16px;
}

.sweet-alert p .btn {
  font-size: 16px;
  font-weight: 600;
}

.sweet-alert .text-muted {
  padding: 0 4%;
  margin-top: 24px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--sub-color);
}

.modal-body .card .content {
  padding-bottom: 16px !important;
}

.card .content {
  padding: 16px !important;
}

.modal-title {
  font-weight: 600;
  font-size: 22px;
}

.modal-dialog .card {
  margin-bottom: 0;
}

.main-content .card {
  margin-bottom: 0;
}

.card-calendar .rbc-toolbar .rbc-toolbar-label {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.card-calendar .rbc-calendar {
  margin: -1px;
}

.card-calendar .rbc-toolbar button {
  padding: 4px 12px;
}

.rbc-day-slot .rbc-event-content {
  padding-top: 4px;
  line-height: 15px;
}

.card-calendar .rbc-header {
  text-align: center;
}

.card-calendar .rbc-header a {
  font-weight: 400;
  color: var(--sub-color);
  font-size: 13px;
}

.card-calendar .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 10px 12px;
}

.card-calendar .rbc-selected {
  color: white;
}

.rbc-today {
  background: var(--theme-highlight-color);
}

.rbc-event.rbc-selected {
  color: white;
}

.rbc-event-label {
  white-space: normal;
  font-weight: 400;
}

.html-localized-wrapper .rdw-editor-toolbar {
  padding-right: 76px;
}

.localizable-input button.btn {
  padding: 8px 6px;
}

.localizable-input .dropdown-menu {
  min-width: 80px;
}

.ql-container {
  font-size: 16px !important;
  min-height: 300px !important;
  max-height: 700px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
}

.ql-editor {
  max-height: 680px !important;
  overflow: auto !important;
}

.ql-container .ql-editor p,
.ql-container ul,
.ql-container ol,
.ql-container dl {
  margin-bottom: 1rem !important;
  margin-top: 0;
}

.ql-container .ql-editor h1,
.ql-container .ql-editor h2,
.ql-container .ql-editor h3,
.ql-container .ql-editor h4,
.ql-container .ql-editor h5 {
  margin-bottom: 1rem !important;
  font-weight: bold !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before {
  content: "10px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
  content: "12px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
  content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "Normal (16px)";
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
  content: "18px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
  content: "20px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
  font-size: 20px !important;
}

.appointment-timeline.scaled-timeline-container.horizontal {
  width: 100%;
  margin: 100px 0;
  height: 24px;
}

.scaled-timeline-entry-details.popout {
  border-radius: 4px;
}

.scaled-timeline-entry.popout {
  border-radius: 6px !important;
  min-width: 16px;
}

.appointment-timeline-card {
  padding: 4px 6px;
  font-size: 12px;
}

@media (min-width: 991px) and (max-width: 1280px) {
  .rbc-calendar .rbc-toolbar {
    flex-direction: row;
  }
}

div.google-visualization-tooltip {
  padding: 6px 12px;
}

.react-time-picker__inputGroup {
  display: flex;
  align-items: baseline;
}

.form-group .react-time-picker {
  display: flex;
  height: 40px;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.spinner-border {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  vertical-align: -0.125em;
  border: 0.16em solid;
  border-right: 0.16em solid transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  flex-grow: 0 !important;
  flex-shrink: 0;
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

.error-alert {
  background-color: var(--error-background-color);
  border: 1px solid var(--error-color);
  padding: 1.4rem;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: var(--error-color);
  border-radius: 4px;
  overflow: hidden;
  white-space: pre-line;
  word-break: normal;
}

.login-page .card .header {
  padding-bottom: 16px;
}

.login-page > .content,
.lock-page > .content {
  padding-top: 15vh;
}

.react-select
  .react-select__control
  .react-select__indicators
  .react-select__clear-indicator:before {
  top: 0;
}

@media (min-width: 768px) {
  .modal-dialog {
    margin-top: 10%;
    width: 95%;
    max-width: 640px;
  }

  .wide.modal-dialog {
    width: 95%;
    max-width: 90%;
    max-width: 900px;
  }
}

@media (max-width: 768px) {
  .grid-2col {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .grid-3col {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .grid-2col .col-2span {
    grid-column: 1 / span 1;
  }

  .messaging-page {
    padding: 12px !important;
  }

  .messaging-page .chat-rooms-container {
    width: 20%;
    min-width: 70px;
  }
}

.react-select__multi-value {
  max-width: 200px;
}

.react-select__multi-value__label {
  text-overflow: ellipsis;
}

.custom-select-option {
  padding: 8px 12px;
  cursor: pointer;
}

.custom-select-option:hover,
.custom-select-option.focused {
  background: var(--theme-hover-color);
}

.custom-select-option.selected {
  background: var(--theme-dark-color);
  color: white;
}

.main-panel {
  scrollbar-width: thin;
  scrollbar-color: #8e8d8d transparent;
  width: calc(100% - 232px);
}

::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: "transparent";
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.2);
  background-color: #8e8d8d;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8e8d8d99;
}

.main-panel .main-content {
  min-height: calc(100vh - 66px);
}

.messaging-page .card {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 68px - 48px);
  overflow: hidden;
  min-height: calc(100vh - 68px - 48px);
}

.messaging-page .card > .content {
  overflow: hidden;
  flex-grow: 1;
}

.chat-container {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.chat-container > .separator.vertical {
  margin-left: 6px;
  margin-right: 16px;
}

.chat-rooms-container {
  width: 35%;
  max-width: 400px;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 68px - 82px);
  min-height: calc(100vh - 68px - 82px);
  scrollbar-width: thin;
  scrollbar-color: #8e8d8d transparent;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-right: 6px;
  flex-grow: 1;
  justify-content: stretch;
}

.chat-rooms-container .chat-rooms-placeholder {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.chat-container .separator.horizontal {
  flex-shrink: 0;
}

.chat-rooms-container::-webkit-scrollbar {
  width: 4px;
}

.chat-rooms-container::-webkit-scrollbar-thumb {
  border-radius: 6px;
  box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.2);
  background-color: rgb(204, 204, 204);
}

.chat-room-list-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  border-radius: 6px;
  transition: all 0.25s ease-in-out;
  color: var(--text-color);
  position: relative;
  border: 1px solid transparent;
}

.chat-room-list-item .chat-unread-badge {
  position: absolute;
  top: 50%;
  left: 3px;
  background: var(--theme-color);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
}

.chat-room-list-item .chat-room-titles {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  overflow: hidden;
}

.chat-room-title,
.chat-header-title {
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-room-subtitle,
.chat-header-subtitle {
  font-weight: 400;
  color: var(--sub-color);
  font-size: 12px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-room-list-item .chat-room-avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border: 1px solid var(--border-color);
  object-fit: cover;
  border-radius: 50%;
  flex-shrink: 0;
}

.chat-room-list-item:hover {
  cursor: pointer;
  background: var(--border-hover-color);
}

.chat-room-list-item.selected,
.chat-room-list-item:active {
  color: var(--theme-color);
  background: var(--theme-hover-color);
  border-color: var(--theme-border-color);
}

.chat-messages-container {
  width: 65%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  max-height: calc(100vh - 68px - 82px);
}

.chat-messages-container .chat-messages-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--sub-color);
  text-align: center;
  line-height: 22px;
  font-size: 13px;
}

.chat-messages-container .chat-header {
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 12px;
  flex-grow: 0;
  overflow: hidden;
}

.chat-header .chat-header-avatar {
  width: 48px;
  height: 48px;
  overflow: hidden;
  border: 1px solid var(--border-color);
  object-fit: cover;
  border-radius: 50%;
  flex-shrink: 0;
}

.chat-header .chat-header-titles {
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
}

.chat-messages-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  margin-right: -12px;
}

.chat-messages-list .chat-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.chat-typing-status {
  color: var(--sub-color);
  font-size: 12px;
  padding: 3px 14px;
  margin-bottom: 16px;
  background: var(--border-color);
  border-radius: 12px;
  margin-right: auto;
}

.chat-container .chat-input {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: stretch;
}

.chat-input input {
  border-radius: 6px;
  padding-right: 40px;
}

.chat-input svg {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 6px;
  top: 8px;
  color: var(--theme-color);
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.chat-input svg:hover {
  color: var(--theme-dark-color);
  cursor: pointer;
}

.chat-input svg.disabled {
  color: var(--sub-color);
  opacity: 0.6;
  cursor: initial;
}

.chat-container .infinite-scroll-component {
  gap: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 12px;
  overflow-x: hidden !important;
}

.chat-container .chat-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.chat-message-text {
  position: relative;
  display: flex;
  padding: 10px 12px;
  flex-direction: column;
  border-radius: 6px;
  color: var(--text-color);
  width: 80%;
  text-align: left;
  align-items: flex-start;
  background-color: var(--border-color);
  font-size: 13px;
  font-weight: 400;
}

.chat-message-time {
  font-size: 11px;
  font-weight: 400;
  color: var(--sub-color);
}

.chat-message.self {
  align-items: flex-end;
}

.chat-message.self .chat-message-text {
  background: var(--theme-color);
  color: white;
  text-align: right;
}

.chat-error-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 100;
}

.messaging-badge {
  width: 7px;
  height: 7px;
  background: var(--theme-color);
  border-radius: 50%;
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}

.block-group {
  padding: 16px 20px;
  border: 1px solid var(--border-color);
  border-radius: 7px;
}

.service-zones-list::before {
  content: "";
  position: absolute;
  inset: 0px;
  transform: translateZ(0);
  background-color: #fcfcfcf9;
  transform-origin: 50% 0%;
  transition: transform 0.25s ease 0s;
  backdrop-filter: blur(8px);
  background-color: rgba(252, 252, 252, 0.94);
}

.service-zones-list .zones-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: calc(100% - 32px);
  max-height: 100%;
  position: relative;
  margin-right: -8px;
  padding-right: 8px;
  margin-top: -16px;
  margin-bottom: -16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.service-zones-list .zone-view-item {
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 12px;
  font-size: 13px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.zone-view-item:hover {
  background: var(--border-hover-color);
  border-color: var(--border-dark-color);
}

.zone-view-item.selected {
  background: var(--theme-hover-color);
  border-color: var(--theme-border-color);
}

.zone-view-item .zone-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--text-color);
}

.zone-view-item .zone-subtitle {
  font-size: 12px;
  color: var(--sub-color);
}

.zone-view-item.selected .zone-title {
  color: var(--theme-color);
}

.corporate-services-table,
.corporate-services-table .rt-table,
.corporate-services-table .rt-tbody,
.corporate-services-table .rt-tr-group,
.corporate-services-table .rt-td {
  overflow: visible;
}

.corporate-services-table .rt-td {
  padding: 24px 8px;
}

.week-day-option {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  padding: 8px 4px;
  border: 2px solid var(--border-color);
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.week-day-option:hover {
  background: var(--border-hover-color);
}

.week-day-option.selected {
  border-color: var(--theme-color);
}

.services-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.service-card {
  display: flex;
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 7px;
  padding: 10px;
  align-items: center;
  gap: 12px;
  background: #fdfdfd;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.service-card img {
  width: 72px;
  height: 72px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
}

.service-card .service-title {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.service-card .service-price {
  font-weight: 400;
  color: var(--theme-color);
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.service-card button {
  border-color: transparent;
}

.services-grid .service-drag-container[aria-pressed="true"] {
  z-index: 10;
}

.color-preview {
  width: 64px;
  height: 18px;
  border-radius: 4px;
}

#title-color-popover,
#descr-color-popover {
  background-color: transparent;
}

.question-card {
  --shadow-color: 0deg 0% 0%;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 16px 16px;
  border: 1px solid #eee;
  border-radius: 7px;
  box-shadow: 0.1px 0.5px 0.6px hsl(var(--shadow-color) / 0.04),
    0.3px 1.4px 1.6px -0.8px hsl(var(--shadow-color) / 0.04),
    0.7px 3.4px 3.9px -1.7px hsl(var(--shadow-color) / 0.04),
    1.7px 8px 9.2px -2.5px hsl(var(--shadow-color) / 0.04);
}

.question-card .separator {
  border-color: #eee;
}

.question-card .question-option {
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 4px 12px;
  font-size: 13px;
  color: var(--sub-color);
}

.notes-bubble {
  border: 1px solid var(--theme-dark-color);
  background: var(--theme-hover-color);
  color: var(--theme-dark-text);
  border-radius: 5px;
  min-height: 24px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 12px 5px 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  overflow: hidden;
  margin-right: auto;
}

.notes-bubble .btn {
  padding: 3px 3px;
  border-radius: 3px;
  margin-right: -8px;
}

.notes-bubble svg {
  width: 16px;
  height: 16px;
  color: var(--theme-dark-color);
}

.checkbox + .checkbox,
.radio + .radio {
  margin: 0;
}

.checkbox {
  margin: 0;
}

.btn-simple:hover {
  color: var(--theme-dark-text);
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.questionnaire-notes {
  background: var(--border-hover-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 6px 12px;
  color: #434343;
  font-weight: 400;
  font-size: 13px;
}

.appointment-service-card {
  border: 1px solid #eee;
  border-radius: 7px;
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

@media (max-width: 992px) {
  .main-panel {
    width: 100%;
  }
}
